import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "gatsby"
import { ImageModule } from "../common/Image_Module"
import './assets/styles/_index.scss';
import { getWhatsAppURL } from '../../site/utils';
import { registerInterestURL } from '../../site/urls';

const OffPlanBanner = (props) => {

    let processedImages = JSON.stringify({});
    if (props?.offplanData?.imagetransforms?.images_Transforms) {
      processedImages = props?.offplanData.imagetransforms.images_Transforms;
    }

    return (
        <section className="offplan-banner-wrapper">
            <ImageModule
                imagename={"offplan.images.bannerImg"}
                ImageSrc={props?.offplanData?.images[0]}
                ggfx_results={props?.offplanData?.ggfx_results}
                altText={props?.offplanData?.title}
                strapi_id={props?.offplanData?.id}
                className="banner-img"
            />
            <div className="overlay-bg"></div>

            <Container className="banner-search-container">
                <Row>
                    <Col>
                        {
                            props?.offplanData?.display_address &&
                            <h1 className="banner-heading">{props?.offplanData?.display_address}</h1>
                        }

                        {
                            props?.offplanData?.price &&
                            <div className="price-details">From AED {props?.offplanData?.price.toLocaleString()}</div>
                        }
                        <ul className="list-inline offplan-list-item">
                            {
                                props?.offplanData?.title &&
                                <li className="list-inline-item">
                                    <div className="d-flex align-items-center">
                                        <i className="icon icon-offplan-beds"></i>
                                        <span>{props?.offplanData?.title}</span>
                                    </div>
                                </li>
                            }
                            {
                                props?.offplanData?.area &&
                                <li className="list-inline-item">
                                    <div className="d-flex align-items-center">
                                        <i className="icon icon-offplan-loc"></i>
                                        <span>{props?.offplanData?.area}</span>
                                    </div>
                                </li>
                            }
                        </ul>
                        <ul className="list-inline offplan-btn-list d-xl-block d-none">
                            <li className="list-inline-item">
                                <Link to={`${registerInterestURL}?pid=${props?.offplanData?.id}`} className="button button-white"><i className="icon icon-offplan-mail"></i> <span>Register Interest</span></Link>
                            </li>
                            {
                                props?.offplanData?.crm_negotiator_id?.phone &&
                                <li className="list-inline-item">
                                    <Link href={"tel:" + props?.offplanData?.crm_negotiator_id?.phone} className="button button-white-outline"><i className="icon icon-offplan-phone"></i> <span>{props?.offplanData?.crm_negotiator_id?.phone}</span></Link>
                                </li>
                            }
                            {
                                props?.offplanData?.crm_negotiator_id?.phone &&
                                <li className="list-inline-item">
                                    <Link href={getWhatsAppURL(props?.offplanData?.crm_negotiator_id?.phone)} target="_blank" className="button button-white-outline"><i className="icon icon-offplan-whatsapp"></i> <span>Click to whatsapp</span></Link>
                                </li>
                            }
                        </ul>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default OffPlanBanner